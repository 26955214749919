import { getAppRelationships, getApps } from '@/api'
import { ForbiddenError } from '@/errors'
import { AppRelationshipsResponse, AppsResponse, AppStatus } from '@/types'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '../index'
import typedStore from '../typedStore'

@Module({ dynamic: true, store, name: 'apps', namespaced: true })
export default class Apps extends VuexModule {
  loaded = false
  apps: AppsResponse = []
  appsRelationships: Record<number, AppRelationshipsResponse> = {}

  @Mutation
  resetState() {
    this.loaded = false
    this.apps = []
    this.appsRelationships = {}
  }

  @Mutation
  setLoaded(payload: boolean) {
    this.loaded = payload
  }

  @Mutation
  setApps(payload: AppsResponse) {
    this.apps = payload
  }

  @Mutation
  setAppRelationships(payload: { appId: number; relationships: AppRelationshipsResponse }) {
    Vue.set(this.appsRelationships, payload.appId, payload.relationships)
  }

  get app() {
    return (id: number) => {
      return this.apps.find((app) => app.id.toString() === id.toString())
    }
  }

  get hostname() {
    return (id: number) => {
      const app = this.app(id)
      return app && app.hostname
    }
  }

  get currentApp() {
    return this.appIdFromRoute ? this.app(this.appIdFromRoute) : undefined
  }

  get currentAppName() {
    const currentApp = this.currentApp
    return currentApp ? currentApp.name : ''
  }

  get appRelationships() {
    if (this.appIdFromRoute) {
      return this.appsRelationships[this.appIdFromRoute] ?? []
    }
    return []
  }

  get canRead() {
    const app = this.currentApp
    return app ? app.read : false
  }

  get canWrite() {
    const app = this.currentApp
    return app ? app.write : false
  }

  get canPublish() {
    const app = this.currentApp
    return app ? app.publish : false
  }

  get canInternal() {
    const app = this.currentApp
    return app ? app.internal : false
  }

  get isLive() {
    const app = this.currentApp
    return app ? app.status === AppStatus.LIVE : false
  }

  get readOnly() {
    return this.canRead && !this.canWrite && !this.canPublish
  }

  get orgSlug() {
    const app = this.currentApp
    return app && app.orgSlug
  }

  get appSlug() {
    const app = this.currentApp
    return app && app.appSlug
  }

  get appType() {
    const app = this.currentApp
    return app && app.appType
  }

  get bubblesOnly() {
    return this.apps.filter((item) => item.appType === 'bubbles')
  }

  get ratesCalcOnly() {
    return this.apps.filter((item) => item.appType === 'rates-calc')
  }

  get allApps() {
    return this.apps
  }

  get appIdFromRoute() {
    return typedStore.currentRoute.appId
  }

  @Action({ rawError: true })
  async getApps() {
    try {
      const apps = await getApps()
      if (Array.isArray(apps)) {
        this.setApps(apps)
      }
    } catch (err) {
      if (err instanceof ForbiddenError) {
        this.setApps([])
      }
    }
  }

  @Action({ rawError: true })
  async getAppRelationships() {
    const appId = this.appIdFromRoute
    if (appId) {
      const relationships = await getAppRelationships({ appId })
      this.setAppRelationships({ appId, relationships })
    }
  }
}
