import { App, AppUserPermission, AwsMarketplaceCustomer, Organisation, User, Version } from '.'

export type AppIdParam = { appId: App['id'] }

export type VersionIdParam = { versionId: Version['id'] }

export type OrganisationIdParam = { orgId: Organisation['id'] }

/** App details including permissions for the current user */
export interface AppResponseItem {
  id: App['id']
  name: App['name']
  hostname: App['hostname']
  status: App['status']
  read: AppUserPermission['read'] | null
  write: AppUserPermission['write'] | null
  publish: AppUserPermission['publish'] | null
  internal: boolean
  created: App['created']
  modified: App['modified']
  orgSlug: Organisation['slug'] | null
  appSlug: App['slug'] | null
  appType: App['appType']
}

export type AppsResponse = AppResponseItem[]

export interface UserProfile {
  email: string
  name: string
  picture: string | null
  /* eslint-disable camelcase */
  user_id: string | null
  app_metadata?: object
  /* eslint-enable camelcase */
}

export interface UserPermission {
  id: User['id']
  profile: UserProfile
  read: AppUserPermission['read']
  write: AppUserPermission['write']
  publish: AppUserPermission['publish']
}

/** Current organisation's users who have access to the specified app */
export type AppPermissionsResponse = Required<UserPermission>[]

export type SuccessResponse = 'Success'

export type PostOrgSlugResponse =
  | SuccessResponse
  | 'You do not have publish permissions for this app'
  | 'No slug'
  | 'You cannot update this organisation'
  | string

export type NewRatesCalcVersionResponse = {
  data?: {
    newVersion: {
      version: {
        id: string
      }
    }
  }
}

export interface AwsMarketplaceCustomerResponse {
  /* eslint-disable camelcase */
  customer_id: AwsMarketplaceCustomer['id']
  dimension: AwsMarketplaceCustomer['dimension']
  expiration_date: AwsMarketplaceCustomer['expirationDate']
  product_code: AwsMarketplaceCustomer['productCode']
  subscription_status: AwsMarketplaceCustomer['status']
  /* eslint-enable camelcase */
}

export interface FeedbackByPath {
  path: string
  friendly_path: string
  comments: { comment: string; time: string }[]
  ratings: { rating: number; count: number }[]
}

export interface FeedbackResponse {
  feedback: FeedbackByPath[]
  feedback_question_id: string
  rating_type: string
  subtitle: string
  title: string
}

export interface OrganisationResponse {
  name: Organisation['name']
  contact: Organisation['contactId']
  id: Organisation['id']
  /* eslint-disable camelcase */
  self_registration_email_domains: Organisation['selfRegistrationEmailDomains']
  aws_marketplace_customer: AwsMarketplaceCustomerResponse | null
  /* eslint-enable camelcase */
}

export interface OrganisationAppResponse {
  id: App['id']
  organisation: App['organisation']['id']
  name: App['name']
  // eslint-disable-next-line camelcase
  current_version: App['currentVersion'] | null
  hostname: App['hostname'] | null
  status: App['status']
}

export interface OrganisationUserResponse {
  username: User['username']
  admin: User['admin']
  organisation: User['organisation']['id']
}

export interface UserResponse {
  username: string
  admin: boolean
  organisation: Organisation['id']
}

export interface VersionItem {
  name: Version['name']
  id: Version['id']
  created: Version['created']
  modified: Version['modified']
  showMap: boolean
}

export enum AppRelationshipType {
  Report = 'Report',
}

export interface AppRelationship {
  appId: App['id']
  type: AppRelationshipType
}

export type AppRelationshipsResponse = AppRelationship[]
