import { Module, VuexModule } from 'vuex-module-decorators'
import store from './index'

// types based on vue-route state
@Module({ dynamic: true, store, name: 'currentstate', namespaced: true })
export default class Currentstate extends VuexModule {
  get name(): string | null | undefined {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.name
  }

  get path(): string {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.path
  }

  get hash(): string {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.hash
  }

  get query(): Record<string, string | (string | null)[]> {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.query
  }

  get params(): Record<string, string> {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route?.params
  }

  get fullPath(): string {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.fullPath
  }

  get meta(): any {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.meta
  }

  get from(): any {
    // @ts-ignore untyped store import, explicitly adding types
    return store.state.route.from
  }

  get appId(): number | undefined {
    return this.params?.appId ? Number(this.params.appId) : undefined
  }
}
