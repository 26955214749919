import {
  OrganisationAppResponse,
  OrganisationIdParam,
  OrganisationResponse,
  OrganisationUserResponse,
} from '@/types'
import { authGet, awaitRequestJson } from './helpers'

export function getOrganisation() {
  return awaitRequestJson<OrganisationResponse>(authGet('organisation'))
}

export function getOrgApps() {
  return awaitRequestJson<OrganisationAppResponse[]>(authGet('organisation/apps'))
}

export function getOrgUsers(params: OrganisationIdParam) {
  return awaitRequestJson<OrganisationUserResponse[]>(authGet('organisation/users', params))
}
