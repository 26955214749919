// Route names
export const ROUTE_FORBIDDEN = 'forbidden'
export const ROUTE_APPS = 'apps'
export const ROUTE_CALLBACK = 'callback'
export const ROUTE_ENGAGEMENT = 'engagement'
export const ROUTE_ORGANISATION = 'organisation'
export const ROUTE_VERSIONS = 'versions'
export const ROUTE_INITIAL = ROUTE_APPS
export const ROUTE_MAINTENANCE = 'maintenance'

// Bubbles route names
export const ROUTE_PUBLIC = 'public'
export const ROUTE_BUILDER = 'builder/sidebar-settings'
export const ROUTE_DATATABLES = 'datatables'
export const ROUTE_UPDATE = 'update'

export const bubblesHref = (
  path: string,
  appId: number,
  versionId: number,
  query: Record<string, string> = {},
) => {
  if (!process.env.VUE_APP_BUBBLES_URL) {
    return undefined
  }
  const url = new URL(
    `${process.env.VUE_APP_BUBBLES_URL}/app/${appId}/version/${versionId}/${path}`,
  )
  for (const key in query) {
    url.searchParams.append(key, query[key])
  }
  return url.toString()
}

export const bubblesInternalHref = (
  path: string,
  orgSlug: string,
  appSlug: string,
  query: Record<string, string> = {},
) => {
  if (!process.env.VUE_APP_BUBBLES_URL) {
    return undefined
  }
  const url = new URL(`${process.env.VUE_APP_BUBBLES_URL}/${orgSlug}/${appSlug}/${path}`)
  for (const key in query) {
    url.searchParams.append(key, query[key])
  }
  return url.toString()
}

export const ratesCalcHref = (appId: string, versionId: string) => {
  if (!process.env.VUE_APP_RATES_CALC_URL) {
    return undefined
  }
  return `${process.env.VUE_APP_RATES_CALC_URL}/${appId}/${versionId}`
}

// Model enums
export const BUBBLES = 'bubbles'
export const RATES_CALC = 'rates-calc'
export const CONSOLIDATED = 'consolidated'

// Environment Variables
export const STUDIO_URL = process.env.VUE_APP_STUDIO_URL
export const GRAPHQL_URL = process.env.VUE_APP_GRAPHQL_HTTP
export const BUBBLES_URL = process.env.VUE_APP_BUBBLES_URL
export const RATES_CALC_URL = process.env.VUE_APP_RATES_CALC_URL
export const API_ADDRESS = process.env.VUE_APP_API_ADDRESS

export const AUTH0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN
export const AUTH0_AUDIENCE = process.env.VUE_APP_AUTH0_AUDIENCE
export const AUTH0_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID
export const AUTH0_TOKEN_CLIENT_ID = process.env.VUE_APP_AUTH0_TOKEN_CLIENT_ID

export const APP_TYPE = process.env.VUE_APP_TYPE
export const LOCAL_PORT = process.env.VUE_APP_LOCAL_PORT
export const RELEASE = process.env.VUE_APP_RELEASE
export const NODE_ENV = process.env.NODE_ENV
export const MAINTENANCE_MODE = process.env.VUE_APP_MAINTENANCE_MODE === 'true'
