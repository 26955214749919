import Vue from 'vue'
import Router from 'vue-router'

import MaintenanceView from '@/views/MaintenanceView.vue'
import { CallbackView } from '@orbica/vue-modules'

import {
  bubblesInternalHref,
  MAINTENANCE_MODE,
  NODE_ENV,
  ROUTE_APPS,
  ROUTE_CALLBACK,
  ROUTE_ENGAGEMENT,
  ROUTE_FORBIDDEN,
  ROUTE_MAINTENANCE,
  ROUTE_ORGANISATION,
  ROUTE_PUBLIC,
  ROUTE_VERSIONS,
} from '@/constants'
import { requiresAuthGuard } from './routeGuards'

// Recommended fix to avoid navigation failure errors
// Replicates vue-router-next behaviour
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
// @ts-ignore changing underlying Router type behaviour
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  // @ts-ignore changing underlying Router type behaviour
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

/* istanbul ignore next */
if (NODE_ENV !== 'test') {
  Vue.use(Router)
}

const ForbiddenView = () => import(/* webpackChunkName: "apps-view" */ '@/views/ForbiddenView.vue')
const AppsView = () => import(/* webpackChunkName: "apps-view" */ '@/views/AppsView.vue')
const EngagementView = () =>
  import(/* webpackChunkName: "engagement-view" */ '@/views/EngagementView.vue')
const OrgView = () => import(/* webpackChunkName: "org-view" */ '@/views/OrgView.vue')
const VersionsView = () =>
  import(/* webpackChunkName: "versions-view" */ '@/views/VersionsView.vue')

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/forbidden',
      name: ROUTE_FORBIDDEN,
      component: ForbiddenView,
    },
    {
      path: '/apps',
      name: ROUTE_APPS,
      component: AppsView,
      meta: { requiresAuth: true },
    },
    {
      path: '/app/:appId',
      name: ROUTE_VERSIONS,
      component: VersionsView,
      meta: { requiresAuth: true },
    },
    {
      path: '/app/:appId/engagement',
      name: ROUTE_ENGAGEMENT,
      component: EngagementView,
      meta: { requiresAuth: true },
    },
    {
      path: '/organisation',
      name: ROUTE_ORGANISATION,
      component: OrgView,
      meta: { requiresAuth: true },
    },
    {
      path: '/callback',
      name: ROUTE_CALLBACK,
      component: CallbackView,
    },
    ...(MAINTENANCE_MODE
      ? [
          {
            name: ROUTE_MAINTENANCE,
            path: '/maintenance',
            component: MaintenanceView,
            meta: { requiresAuth: true },
          },
        ]
      : []),
    {
      path: '/:orgSlug/:appSlug/:mainpanel?/:storyPath*',
      name: 'router-internal',
      beforeEnter(to, from, next) {
        const { orgSlug, appSlug, mainpanel: mainpanelParam, storyPath: storyPathParam } = to.params
        const mainpanel = mainpanelParam || ROUTE_PUBLIC
        const storyPath = storyPathParam ? `/${storyPathParam}` : ''
        const href = bubblesInternalHref(mainpanel + storyPath, orgSlug, appSlug)
        if (href) {
          window.location.href = href
        } else {
          next({ name: ROUTE_APPS })
        }
      },
    },
    {
      path: '*',
      redirect: { name: ROUTE_APPS },
    },
  ],
})

/* istanbul ignore next */
router.beforeEach((to, from, next) => requiresAuthGuard(to, from, next))

export default router
