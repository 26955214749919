
import Component from 'vue-class-component'
import Vue from 'vue'
import { useNotificationsStore } from '@/store/notifications'

@Component
export default class Notifications extends Vue {
  notificationsStore = useNotificationsStore()

  get notifications() {
    return this.notificationsStore.notifications
  }

  removeNotification(id: string) {
    this.notificationsStore.removeNotification(id)
  }
}
