import { API_ADDRESS, APP_TYPE, GRAPHQL_URL, RELEASE } from '@/constants'
import router from '@/router'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'

const isProd = process.env.NODE_ENV === 'production'

Sentry.init({
  dsn: isProd ? 'https://0e9961354e8948908a79f57b5e75a4b7@sentry.io/1536711' : undefined,
  Vue,
  logErrors: !isProd,
  environment: APP_TYPE,
  release: RELEASE,
  integrations: [
    new Integrations.BrowserTracing({
      beforeNavigate: (context) => {
        return {
          ...context,
          name: router.currentRoute.name || router.currentRoute.fullPath,
        }
      },
      tracingOrigins: [
        ...(API_ADDRESS ? [API_ADDRESS] : []),
        ...(GRAPHQL_URL ? [GRAPHQL_URL] : []),
        /^\//,
      ],
    }),
  ],
  tracesSampleRate: 0.25,
  tracingOptions: {
    trackComponents: true,
  },
})
