
import Vue from 'vue'
import Component from 'vue-class-component'
import * as Sentry from '@sentry/vue'
import AppNav from '@/components/AppNav/AppNav.vue'
import { ROUTE_FORBIDDEN } from '@/constants'
import { ForbiddenError } from '@/errors'
import Notifications from '@/components/Notifications.vue'

const components = {
  AppNav,
  Notifications,
}

const PAGE_TITLE = 'OrbViz Studio'

// eslint-disable-next-line no-use-before-define
@Component<App>({
  components,
  watch: {
    isAuthenticated: {
      async handler(isAuthenticated) {
        if (isAuthenticated) {
          await this.handleAuthUser()
        }
      },
      immediate: true,
    },
  },
})
export default class App extends Vue {
  metaInfo() {
    return {
      title: PAGE_TITLE,
      meta: [
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: PAGE_TITLE },
      ],
    }
  }

  get isAuthenticated() {
    return this.$auth.isAuthenticated
  }

  async handleAuthUser() {
    try {
      await this.$typedStore.org.getUserAdminStatus()
      await this.$typedStore.org.getDetails()
      await this.$typedStore.apps.getApps()
    } catch (err) {
      if (err instanceof ForbiddenError) {
        await this.$router.push({ name: ROUTE_FORBIDDEN })
      } else {
        throw err
      }
    }
    this.setGaUser()
    this.setSentryUser()
    this.setFreshdeskUser()
  }

  setGaUser() {
    this.$ga.set({ userId: this.$auth.user.sub })
  }

  setSentryUser() {
    Sentry.configureScope((scope) => {
      scope.setUser({ email: this.$auth.user.email })
    })
  }

  setFreshdeskUser() {
    FreshworksWidget('identify', 'ticketForm', {
      name: this.$auth.user.name,
      email: this.$auth.user.email,
    })
  }

  openHelpWidget() {
    FreshworksWidget('open')
  }
}
