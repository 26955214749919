import { App, AppIdParam, SuccessResponse } from '@/types'
import { authPost, awaitRequestJson } from './helpers'

export * from './apps'
export * from './auth'
export * from './graphQL'
export * from './org'
export * from './users'
export * from './versions'

export function sendPublishSetupRequestEmail(hostname: App['hostname'], params: AppIdParam) {
  return awaitRequestJson<SuccessResponse>(authPost('email/publish-request', { hostname }, params))
}

export function duplicateModel(modelName: string, from: string, to: string, body: object) {
  // TODO: Handle response
  return awaitRequestJson(authPost(`duplicate-${modelName}/${from}/${to}`, body))
}
