import { AUTH0_AUDIENCE, AUTH0_DOMAIN, AUTH0_TOKEN_CLIENT_ID } from '@/constants'
import { Auth0RefreshTokenResponse } from '@/types'
import { awaitRequestJson } from './helpers'

export function getRefreshToken(username: string, password: string) {
  if (!(AUTH0_DOMAIN && AUTH0_TOKEN_CLIENT_ID && AUTH0_AUDIENCE)) {
    throw new Error('Invalid Auth0 variables')
  }

  const url = `https://${AUTH0_DOMAIN}/oauth/token`
  const body = new URLSearchParams({
    grant_type: 'password',
    client_id: AUTH0_TOKEN_CLIENT_ID,
    audience: AUTH0_AUDIENCE,
    scope: 'offline_access openid',
    username,
    password,
  })
  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  })

  return awaitRequestJson<Auth0RefreshTokenResponse>(
    fetch(url, {
      headers,
      method: 'POST',
      body,
    }),
  )
}
