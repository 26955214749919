import Apps from './admin/apps'
import Org from './admin/org'
import Versions from './admin/versions'
import CurrentRoute from './currentRoute'
import Features from './features'

import { getModule } from 'vuex-module-decorators'

export default {
  apps: getModule(Apps),
  org: getModule(Org),
  versions: getModule(Versions),
  currentRoute: getModule(CurrentRoute),
  features: getModule(Features)
}
