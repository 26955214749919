import { GRAPHQL_URL } from '@/constants'
import { NewRatesCalcVersionResponse, RatesCalcPresentationMode } from '@/types'
import { awaitRequestJson, generateHeaders } from './helpers'

export async function newRatesCalcVersion(
  appId: number,
  versionName: string,
  presentationMode: RatesCalcPresentationMode,
  usePlaceholderContent: boolean,
) {
  const headers = await generateHeaders('application/json')
  const body = {
    operationName: 'NewVersion',
    query: `mutation NewVersion ($appId: Int!, $versionName: String!, $presentationMode: PresentationMode! $usePlaceholderContent: Boolean) {
          newVersion(appId: $appId, versionName: $versionName, presentationMode: $presentationMode, usePlaceholderContent: $usePlaceholderContent) {
            version {
              id
            }
          }
        }`,
    variables: {
      appId,
      versionName,
      presentationMode,
      usePlaceholderContent,
    },
  }
  if (!GRAPHQL_URL) {
    throw new Error('No GraphQL URL variable provided')
  }
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(body),
  }
  const response = await awaitRequestJson<NewRatesCalcVersionResponse>(fetch(GRAPHQL_URL, options))
  if (!(response instanceof Blob)) {
    return response?.data?.newVersion?.version?.id
  }
}
