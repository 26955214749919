import {
  AppIdParam,
  SuccessResponse,
  Version,
  VersionIdParam,
  VersionItem,
} from '@/types'
import { authGet, authPost, awaitRequestJson, awaitRequestJsonOrNull } from './helpers'

export function getVersionList(params: AppIdParam) {
  return awaitRequestJson<VersionItem[]>(authGet('versions', params))
}

export function postNewVersion(name: Version['name'], params: AppIdParam) {
  if (name) return awaitRequestJson<Version['id']>(authPost('version', { name }, params))
  else return awaitRequestJson<Version['id']>(authPost('version', {}, params))
}

type NoCurrentVersion = -1

export function getCurrentVersionId(params: AppIdParam) {
  return awaitRequestJson<Version['id'] | NoCurrentVersion>(authGet('app/current-version', params))
}

export function postChangeCurrentVersion(newVersionId: Version['id'] | null, params: AppIdParam) {
  return awaitRequestJson<SuccessResponse>(
    authPost('app/current-version', { newVersionId }, params),
  )
}

export function postDeleteVersion(versionId: Version['id']) {
  return awaitRequestJson<SuccessResponse>(authPost('version/archive', { versionId }))
}

export function postCloneVersion(versionId: Version['id'], name: Version['name']) {
  if (name) return awaitRequestJson<Version['id']>(authPost('version/clone', { versionId, name }))
  else return awaitRequestJson<Version['id']>(authPost('version/clone', { versionId }))
}

export function getVersionStatus() {
  return awaitRequestJson<Version['status']>(authGet('version/status'))
}

export function postVersionStatus(status: Version['status'], params: AppIdParam & VersionIdParam) {
  return awaitRequestJson<SuccessResponse>(authPost('version/status', { status }, params))
}

export function postVersionName(name: Version['name'], params: VersionIdParam) {
  // Todo: response
  return awaitRequestJson(authPost('version/name', { name }, params))
}

// Todo: remove any
export function postVersionUpdateData(payload: any, params: any) {
  // Todo: response
  return awaitRequestJson(authPost('version/update-data', payload, params))
}
