import {
  App,
  AppIdParam,
  AppPermissionsResponse,
  AppRelationshipsResponse,
  AppsResponse,
  AppStatus,
  Organisation,
  PostOrgSlugResponse,
  SuccessResponse,
  UserPermission,
} from '@/types'
import { authGet, authPost, awaitRequestJson } from './helpers'

export function getApps() {
  return awaitRequestJson<AppsResponse>(authGet('apps'))
}

export function getHostname(params: AppIdParam) {
  return awaitRequestJson<App['hostname']>(authGet('app/hostname', params))
}

export function postHostname(hostname: string, params: AppIdParam) {
  return awaitRequestJson<SuccessResponse>(authPost('app/hostname', { hostname }, params))
}

export function getAppStatus(params: AppIdParam) {
  return awaitRequestJson<AppStatus>(authGet('app/status', params))
}

export function postAppStatus(status: AppStatus, params: AppIdParam) {
  return awaitRequestJson<SuccessResponse>(authPost('app/status', { status }, params))
}

export function getAppLastUpdate(params: AppIdParam) {
  return awaitRequestJson<App['modified']>(authGet('app/last-update', params))
}

export function getAppPermissions(params: AppIdParam) {
  return awaitRequestJson<AppPermissionsResponse>(authGet('app/permissions', params))
}

export function getAppRelationships(params: AppIdParam) {
  return awaitRequestJson<AppRelationshipsResponse>(authGet('app/relationships', params))
}

export function postAppPermissions(permissions: UserPermission[], params: AppIdParam) {
  return awaitRequestJson<SuccessResponse>(authPost('app/permissions', { permissions }, params))
}

export function postAppSlug(slug: App['slug'], params: AppIdParam) {
  return awaitRequestJson<SuccessResponse | 'No slug'>(authPost('app/slug', { slug }, params))
}

export function postOrgSlug(slug: Organisation['slug'], params: AppIdParam) {
  return awaitRequestJson<PostOrgSlugResponse>(authPost('organisation/slug', { slug }, params))
}

// Todo
export function postAppUpdateData(payload: object, params: object) {
  // Todo
  return awaitRequestJson(authPost('app/update-data', payload, params))
}
