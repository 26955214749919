// Polyfills
import 'url-polyfill'
import 'whatwg-fetch'

// App imports
import Vue from 'vue'
import App from './App.vue'
import './plugins/class-component-hooks'
import router from './router'
import store from './store'

// Dependencies
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { sync } from 'vuex-router-sync'
import './assets/css/custom.scss' // includes Bootstraps styling
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './plugins/authPlugin'
import './plugins/FontAwesomeIcon'
import { growthBookService } from './plugins/growthBookService'
import './plugins/registerAnalytics'
import registerBootstrapComponents from './plugins/registerBootstrapComponents'
import './plugins/sentryPlugin'
import typedStore from './store/typedStore'

declare module 'vue/types/vue' {
  interface Vue {
    $typedStore: typeof typedStore
  }
}

// @ts-ignore window.Cypress
const isProd = process.env.NODE_ENV === 'production' && !window.Cypress

console.log('Release', process.env.VUE_APP_RELEASE || 'development')

async function init() {
  // Wait for GrowthBook to initialize
  await growthBookService.initialize({
    clientKey: process.env.VUE_APP_GROWTHBOOK_SDK_KEY ?? '',
    enableDevMode: !isProd,
  })

  // Register plugins and components
  Vue.use(PiniaVuePlugin)
  Vue.use(Vuelidate)
  Vue.use(VueMeta)
  registerBootstrapComponents(Vue)

  sync(store, router)

  Vue.config.productionTip = false
  Vue.prototype.$typedStore = typedStore

  const app = new Vue({
    store,
    router,
    render: (h) => h(App),
    pinia: createPinia(),
  }).$mount('#app')

  if (!isProd) {
    // Do not make available on production
    // @ts-ignore window.app
    window.app = app
  }
}

init()
