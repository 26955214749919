import { generateUuid } from '@/helpers'
import { Notification, NotificationType } from '@/types'
import { defineStore } from 'pinia'
import Vue from 'vue'

export interface INotificationsState {
  notifications: Record<string, Notification>
}

export const useNotificationsStore = defineStore({
  id: 'errors',
  state: (): INotificationsState => ({
    notifications: {},
  }),
  actions: {
    addError(message: string) {
      const id = generateUuid()
      const notification = {
        message,
        type: NotificationType.Error,
      }
      Vue.set(this.notifications, id, notification)
    },

    removeNotification(id: string) {
      Vue.delete(this.notifications, id)
    },
  },
})
