
import Vue from 'vue'
import Component from 'vue-class-component'

import { BuilderNavbar } from '@orbica/vue-modules'
import { ROUTE_APPS, ROUTE_ORGANISATION, APP_TYPE, ROUTE_FORBIDDEN } from '@/constants'
import APIAccessTokensModal from './APIAccessTokensModal.vue'
import AwsSubscriptionStatusModal from './AwsSubscriptionStatusModal.vue'
import { Location } from 'vue-router'

const components = {
  BuilderNavbar,
  APIAccessTokensModal,
  AwsSubscriptionStatusModal,
}

// todo: import from vue-modules
interface BuilderMenuItem {
  label: string
  disabled?: boolean
  active?: boolean
  href?: string
  to?: string | Location
  action?: (params: any[]) => void
}

@Component({ components })
export default class AppNav extends Vue {
  get hasAwsSubscription() {
    return this.$typedStore.org.hasAwsSubscription
  }

  get type() {
    switch (APP_TYPE) {
      case 'development':
        return 'Development'
      case 'staging':
        return 'Staging'
      case 'test':
        return 'Test'
      default:
        return null
    }
  }

  get menuItems(): BuilderMenuItem[] {
    if (this.isForbiddenPage) return []

    return [
      ...(this.multipleApps && !this.isAppsPage
        ? [{ label: 'Applications', to: { name: ROUTE_APPS } }]
        : []),
      ...(this.adminUser && !this.isOrgPage
        ? [{ label: 'Organisation', to: { name: ROUTE_ORGANISATION } }]
        : []),
      ...(this.hasAwsSubscription
        ? [{ label: 'Subscription Status', action: this.showAwsSubscriptionModal }]
        : []),
    ]
  }

  get isAppsPage() {
    return this.$typedStore.currentRoute.name === ROUTE_APPS
  }

  get isOrgPage() {
    return this.$typedStore.currentRoute.name === ROUTE_ORGANISATION
  }

  get isForbiddenPage() {
    return this.$typedStore.currentRoute.name === ROUTE_FORBIDDEN
  }

  get multipleApps() {
    return this.$typedStore.apps.allApps.length > 1
  }

  get adminUser() {
    return this.$typedStore.org.adminUser
  }

  get appName() {
    return this.$typedStore.apps.currentAppName
  }

  showAccessTokenModal() {
    // @ts-ignore todo $bvModal sometimes error, sometimes not
    this.$bvModal.show('api-access-tokens')
  }

  showAwsSubscriptionModal() {
    // @ts-ignore todo $bvModal sometimes error, sometimes not
    this.$bvModal.show('aws-subscription-status')
  }
}
