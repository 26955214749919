<template>
  <b-modal id="api-access-tokens" title="Request or revoke API Tokens" ok-only @hidden="resetModal">
    <p>
      An API Token is a long-lived token tied to a user account. The API Token is exchanged for a
      short-lived access token to be used for API access. Keep your API Tokens secure.
    </p>

    <b-form v-if="!token" @submit.prevent="getToken">
      <b-form-group label="Email">
        <b-input :value="email" disabled />
      </b-form-group>

      <b-form-group
        :disabled="fetching"
        label="Password"
        description="Required to generated API Token"
      >
        <b-input v-model="password" type="password" />
      </b-form-group>

      <b-form-group :disabled="fetching">
        <b-button block variant="primary" type="submit"> Get API Token </b-button>
      </b-form-group>
    </b-form>

    <b-form-group
      v-if="token"
      label="Token"
      description="Save this token in a safe place. You will not be able to view it again once this window has closed."
    >
      <b-input :value="token" disabled />
    </b-form-group>

    <b-alert v-model="showError" dismissible variant="warning">
      {{ error }}
    </b-alert>

    <p>
      To revoke your access tokens, please contact
      <b-link href="mailto:info@orbviz.com"> support </b-link>.
    </p>
  </b-modal>
</template>

<script>
import { getRefreshToken } from '@/api'

export default {
  name: 'APIAccessTokensModal',
  data: () => ({
    password: '',
    fetching: false,
    token: '',
    error: null,
    showError: false,
  }),
  computed: {
    email() {
      return this.$auth.user?.email
    },
  },
  methods: {
    resetModal() {
      this.password = ''
      this.token = ''
    },
    async getToken() {
      this.fetching = true
      this.showError = false
      let response
      try {
        response = await getRefreshToken(this.email, this.password)
        this.token = response.refresh_token
      } catch (e) {
        this.showError = true
        if (e.status === 403) {
          this.error = 'Unauthorised - invalid password?'
        } else {
          this.error = 'Unexpected error - please try again'
          throw e
        }
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>
