import {
  AlertPlugin,
  ButtonGroupPlugin,
  ButtonPlugin,
  CardPlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  PopoverPlugin,
  SpinnerPlugin,
  TablePlugin,
  ToastPlugin,
  VBPopoverPlugin,
  VBTooltipPlugin,
} from 'bootstrap-vue'
import { VueConstructor } from 'vue'

const bootstrapPlugins = [
  LayoutPlugin,
  CardPlugin,
  FormPlugin,
  ButtonPlugin,
  ImagePlugin,
  TablePlugin,
  AlertPlugin,
  FormInputPlugin,
  FormGroupPlugin,
  InputGroupPlugin,
  FormSelectPlugin,
  FormCheckboxPlugin,
  SpinnerPlugin,
  ButtonGroupPlugin,
  ListGroupPlugin,
  FormRadioPlugin,
  ModalPlugin,
  PopoverPlugin,
  LinkPlugin,
  ToastPlugin,
  VBTooltipPlugin,
  VBPopoverPlugin,
]

export default function registerBootstrapComponents(vue: VueConstructor) {
  for (const item of bootstrapPlugins) {
    vue.use(item)
  }
}
