// import appGetters from './appGetters'
import {
  getAppPermissions,
  getOrganisation,
  getOrgApps,
  getOrgUsers,
  getUser,
  postAppPermissions,
} from '@/api'
import {
  App,
  AppPermissionsResponse,
  AppStatus,
  AwsMarketplaceCustomer,
  AwsMarketplaceCustomerResponse,
  Organisation,
  OrganisationAppResponse,
  OrganisationUserResponse,
  User,
} from '@/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '../index'
import typedStore from '../typedStore'
// @ts-ignore import vuex-map-fields
import { getField, updateField } from 'vuex-map-fields'

@Module({ dynamic: true, store, name: 'org', namespaced: true })
export default class Org extends VuexModule {
  orgId: Organisation['id'] = -1
  adminUser: User['admin'] = false
  apps: OrganisationAppResponse[] = []
  currentAppId: App['id'] = -1
  name: Organisation['name'] = ''
  permissions: AppPermissionsResponse = []
  users: OrganisationUserResponse[] | null = null
  emailDomains: Organisation['selfRegistrationEmailDomains'] = []
  awsSubscription: AwsMarketplaceCustomer | null | false = null

  @Mutation
  setAdminUser(payload: User['admin']) {
    this.adminUser = payload
  }

  @Mutation
  setApps(payload: OrganisationAppResponse[]) {
    this.apps = payload
  }

  @Mutation
  setCurrentAppId(payload: App['id']) {
    this.currentAppId = payload
  }

  @Mutation
  setName(payload: Organisation['name']) {
    this.name = payload
  }

  @Mutation
  setPermissions(payload: AppPermissionsResponse) {
    this.permissions = payload
  }

  @Mutation
  setOrgId(payload: Organisation['id']) {
    this.orgId = payload
  }

  @Mutation
  setUsers(payload: OrganisationUserResponse[]) {
    this.users = payload
  }

  @Mutation
  setEmailDomains(payload: Organisation['selfRegistrationEmailDomains']) {
    this.emailDomains = payload || []
  }

  @Mutation
  setAwsSubscription(payload: AwsMarketplaceCustomerResponse | null) {
    if (payload) {
      const {
        customer_id: id,
        dimension,
        expiration_date: expirationDate,
        product_code: productCode,
        subscription_status: status,
      } = payload

      this.awsSubscription = {
        id,
        dimension,
        expirationDate,
        productCode,
        status,
      }
    } else {
      this.awsSubscription = false
    }
  }

  // ...appGetters,
  // May not all be needed under the org namespace and some don't work so commented out
  // Todo: check usage

  get app() {
    return (id: number) => {
      return id && this.apps.find((app) => app.id.toString() === id.toString())
    }
  }

  get hostname() {
    return (id: number) => {
      const app = this.app(id)
      return app && app.hostname
    }
  }

  get appIdFromRoute() {
    return typedStore.currentRoute.appId
  }

  get currentApp() {
    return this.appIdFromRoute ? this.app(this.appIdFromRoute) : undefined
  }

  get currentAppName() {
    const currentApp = this.currentApp
    return currentApp ? currentApp.name : ''
  }

  // get canRead () {
  //   const app = this.currentApp
  //   return app ? app.read : false
  // }

  // get canWrite () {
  //   const app = this.currentApp
  //   return app ? app.write : false
  // }

  // get canPublish () {
  //   const app = this.currentApp
  //   return app ? app.publish : false
  // }

  // get canInternal () {
  //   const app = this.currentApp
  //   return app ? app.internal : false
  // }

  get isLive() {
    const app = this.currentApp
    return app ? app.status === AppStatus.LIVE : false
  }

  // get readOnly () {
  //   return this.canRead && !this.canWrite && !this.canPublish
  // }

  // get orgSlug () {
  //   const app = this.currentApp
  //   return app && app.orgSlug
  // }

  // get appSlug () {
  //   const app = this.currentApp
  //   return app && app.appSlug
  // }

  // get appType () {
  //   const app = this.currentApp
  //   return app && app.appType
  // }

  // End of appGetters import

  get hasAwsSubscription() {
    return Boolean(this.awsSubscription)
  }

  get activeAwsSubscription() {
    if (!this.awsSubscription) {
      return false
    }
    const expirationDate = new Date(this.awsSubscription.expirationDate)
    return new Date() < expirationDate
  }

  @Action({ rawError: true })
  async getOrgData() {
    return Promise.all([this.getOrgDetails(), this.getApps()])
  }

  @Action({ rawError: true })
  async getOrgDetails() {
    await this.getDetails()
    this.getUsers()
  }

  @Action({ rawError: true })
  async getApps() {
    this.setApps((await getOrgApps()) || [])
  }

  @Action({ rawError: true })
  async getDetails() {
    const {
      name,
      id,
      self_registration_email_domains: emailDomains,
      aws_marketplace_customer: awsSubscription,
    } = await getOrganisation()
    this.setName(name)
    this.setOrgId(id)
    this.setEmailDomains(emailDomains)
    this.setAwsSubscription(awsSubscription)
  }

  @Action({ rawError: true })
  async getUserAdminStatus() {
    const user = await getUser()
    this.setAdminUser(!!(user && user.admin))
  }

  @Action({ rawError: true })
  async getPermissions() {
    const appId = this.currentAppId
    this.setPermissions((await getAppPermissions({ appId })) || [])
  }

  @Action({ rawError: true })
  async savePermissions() {
    const appId = this.currentAppId
    await postAppPermissions(this.permissions, { appId })
  }

  @Action({ rawError: true })
  async getUsers() {
    const orgId = this.orgId
    if (orgId !== -1) {
      this.setUsers((await getOrgUsers({ orgId })) || [])
    }
  }

  get getField() {
    return getField(this)
  }

  @Mutation
  updateField(options: { path: string; value: unknown }) {
    return updateField(this, options)
  }
}
