import { library } from '@fortawesome/fontawesome-svg-core'
import { faImage, faQuestionCircle as farQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import {
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCopy,
  faEllipsisH,
  faEye,
  faFileUpload,
  faPen,
  faSearch,
  faTable,
  faTag,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'

// https://www.npmjs.com/package/@fortawesome/vue-fontawesome
library.add(
  faImage,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faCopy,
  faTrash,
  faTable,
  faSearch,
  faFileUpload,
  faEllipsisH,
  farQuestionCircle,
  faEye,
  faPen,
  faTag,
)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
