import { MAINTENANCE_MODE, ROUTE_MAINTENANCE } from '@/constants'
import { authGuard } from '@orbica/vue-modules'
import { NavigationGuard } from 'vue-router'

export const requiresAuthGuard: NavigationGuard = (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (MAINTENANCE_MODE) {
      // If in maintenance mode, all requiresAuth routes should redirect to the maintenance page
      authGuard(to, from, () => next({ name: ROUTE_MAINTENANCE }))
    } else {
      authGuard(to, from, next)
    }
  } else {
    next()
  }
}
