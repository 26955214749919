import { SuccessResponse, UserResponse } from '@/types'
import { authGet, authPost, awaitRequestJson } from './helpers'

export function getUser() {
  return awaitRequestJson<UserResponse>(authGet('user'))
}

interface PostNewUserBody {
  email: string
  // eslint-disable-next-line camelcase
  given_name: string
  // eslint-disable-next-line camelcase
  family_name: string
}

export function postNewUser(body: PostNewUserBody) {
  return awaitRequestJson<SuccessResponse>(authPost('user', body))
}
