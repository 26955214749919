import typedStore from '@/store/typedStore'
import { Attributes, GrowthBook } from '@growthbook/growthbook'
import { getInstance as getAuth0Instance } from '@orbica/vue-modules'
import _isEqual from 'lodash/isEqual'

export interface GrowthBookOptions {
  clientKey: string
  enableDevMode: boolean
}

class GrowthBookService {
  private _growthBook: GrowthBook
  private _initialized: boolean

  constructor() {
    this._growthBook = new GrowthBook()
    this._initialized = false
  }

  async initialize(options: GrowthBookOptions) {
    if (!this._initialized) {
      try {
        this._growthBook = new GrowthBook({
          clientKey: options.clientKey,
          enableDevMode: options.enableDevMode,
        })
        await this._growthBook.loadFeatures({ autoRefresh: true, timeout: 200 })
        this._initialized = true
      } catch (error) {
        console.error('GrowthBook initialization error', error)
      }
    }
  }

  getFeatureValue(key: string, defaultValue: any, appId: number | undefined) {
    this.refreshAttributes(appId)
    return this._growthBook.getFeatureValue(key, defaultValue)
  }

  private refreshAttributes(appId: number | undefined) {
    const auth0 = getAuth0Instance()

    const currentAttributes = this._growthBook.getAttributes()
    const newAttributes: Attributes = {
      loggedIn: auth0?.isAuthenticated ?? false,
      id: auth0?.user?.sub,
      email: auth0?.user?.email,
      environment: process.env.NODE_ENV,
      developer_key: process.env.VUE_APP_GROWTHBOOK_DEVELOPER_KEY ?? '',
      orgId: typedStore.org.orgId,
      appId,
    }

    if (!_isEqual(currentAttributes, newAttributes)) {
      this._growthBook.setAttributes(newAttributes)
    }
  }
}

export const growthBookService = new GrowthBookService()
