import {
  getAppLastUpdate,
  getAppStatus,
  getCurrentVersionId,
  getHostname,
  getVersionList,
} from '@/api'
import { App, Version, VersionItem } from '@/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '../index'
import typedStore from '../typedStore'

// Initial state
const INITIAL_VERSIONS: VersionItem[] = []
const INITIAL_CURRENT_VERSION_ID: number = -1
const INITIAL_HOSTNAME: App['hostname'] = ''
const INITIAL_APP_STATUS: App['status'] = -1
const INITIAL_APP_LAST_UPDATE: App['modified'] = ''

@Module({ dynamic: true, store, name: 'versions', namespaced: true })
export default class Versions extends VuexModule {
  versions: VersionItem[] = INITIAL_VERSIONS
  currentVersionId: number = INITIAL_CURRENT_VERSION_ID
  hostname: App['hostname'] = INITIAL_HOSTNAME
  appStatus: App['status'] = INITIAL_APP_STATUS
  appLastUpdate: App['modified'] = INITIAL_APP_LAST_UPDATE

  @Mutation
  resetState() {
    this.versions = INITIAL_VERSIONS
    this.currentVersionId = INITIAL_CURRENT_VERSION_ID
    this.hostname = INITIAL_HOSTNAME
    this.appStatus = INITIAL_APP_STATUS
    this.appLastUpdate = INITIAL_APP_LAST_UPDATE
  }

  @Mutation
  setVersions(payload: VersionItem[]) {
    this.versions = payload
  }

  @Mutation
  setCurrentVersionId(payload: Version['id']) {
    this.currentVersionId = payload
  }

  @Mutation
  setHostname(payload: App['hostname']) {
    this.hostname = payload
  }

  @Mutation
  setAppStatus(payload: App['status']) {
    this.appStatus = payload
  }

  @Mutation
  setAppLastUpdate(payload: App['modified']) {
    this.appLastUpdate = payload
  }

  get versionName() {
    return (id: Version['id']) => {
      const version = this.versions.find((v) => v.id === id)
      return version ? version.name : ''
    }
  }

  get currentVersion() {
    return this.versions.find((v) => v.id === this.currentVersionId)
  }

  get appIdFromRoute() {
    return typedStore.apps.appIdFromRoute
  }

  @Action({ rawError: true })
  async getVersionData() {
    return Promise.all([
      this.getVersions(),
      this.getCurrentVersion(),
      this.getHostname(),
      this.getAppStatus(),
      this.getAppLastUpdate(),
    ])
  }

  @Action({ rawError: true })
  async getVersions() {
    const appId = this.appIdFromRoute
    if (appId)
      this.setVersions((await getVersionList({ appId })) || INITIAL_VERSIONS)
  }

  @Action({ rawError: true })
  async getCurrentVersion() {
    const appId = this.appIdFromRoute
    if (appId)
      this.setCurrentVersionId((await getCurrentVersionId({ appId })) || INITIAL_CURRENT_VERSION_ID)
  }

  @Action({ rawError: true })
  async getHostname() {
    const appId = this.appIdFromRoute
    if (appId)
      this.setHostname((await getHostname({ appId })) || INITIAL_HOSTNAME)
  }

  @Action({ rawError: true })
  async getAppStatus() {
    const appId = this.appIdFromRoute
    if (appId)
      this.setAppStatus(await getAppStatus({ appId }))
  }

  @Action({ rawError: true })
  async getAppLastUpdate() {
    const appId = this.appIdFromRoute
    if (appId)
      this.setAppLastUpdate((await getAppLastUpdate({ appId })) || INITIAL_APP_LAST_UPDATE)
  }
}
