import { BUBBLES, CONSOLIDATED, RATES_CALC } from '@/constants'

interface CreatedModifiedMixin {
  created: string
  modified: string
}

enum OrganisationStatus {
  ENABLED = 1,
  DISABLED = -1,
}

export interface Organisation extends CreatedModifiedMixin {
  id: number
  name: string
  contactId: number
  selfRegistrationEmailDomains: string[]
  slug: string
  status: OrganisationStatus
}

export enum AppStatus {
  DISABLED = -1,
  INITIAL = 0,
  PENDING = 1,
  LIVE = 2,
  INTERNAL = 3,
}
export type AppType = typeof BUBBLES | typeof RATES_CALC | typeof CONSOLIDATED

export interface App extends CreatedModifiedMixin {
  id: number
  organisation: Organisation
  url: string
  name: string
  currentVersion: number
  hostname: string
  // permissions: AppPermission[]
  slug: string
  status: AppStatus
  appType: AppType
}

enum AwsMarketplaceCustomerSubscriptionStatus {
  CREATED = 'created',
  CHANGED = 'changed',
  UNCHANGED = 'unchanged',
  INACTIVE = 'inactive',
}

export interface AwsMarketplaceCustomer {
  organisation?: Organisation
  id: string // renamed from 'customerId' in frontend - should be fixed todo
  dimension: string
  expirationDate: string // date
  productCode: string
  contactName?: string
  contactEmail?: string
  status: AwsMarketplaceCustomerSubscriptionStatus // renamed from subscriptionStatus in frontend - should be fixed todo
  subscriptionStatusLastChecked?: string // date
  subscriptionStatusChangeMessage?: string
}

export enum UserStatus {
  DISABLED = -1,
  ENABLED = 1,
}

export interface User {
  id: number
  username: string
  organisation: Organisation
  admin: boolean
  firstName: string
  lastName: string
  email: string
  status: UserStatus
}

export interface AppUserPermission extends CreatedModifiedMixin {
  app: App
  user: User
  read: boolean
  write: boolean
  publish: boolean
}

export enum VersionStatus {
  INITIAL = 0,
  BUILT = 1,
  ARCHIVED = 2,
}

export interface Version extends CreatedModifiedMixin {
  id: number
  app: App
  name: string
  source: object
  status: VersionStatus
}

export const FeatureKeys = {
  FEEDBACK: 'feedback',
  USAGE_METRICS: 'usage-metrics',
} as const

export type Feature = (typeof FeatureKeys)[keyof typeof FeatureKeys]

export enum RatesCalcPresentationMode {
  Question = 'QUESTION',
  Product = 'PRODUCT',
}

export enum NotificationType {
  Error = 'danger',
  // Add info, success etc. here as required
}

export interface Notification {
  message: string
  type: NotificationType
}
