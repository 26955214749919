import { APP_TYPE } from '@/constants'
import router from '@/router'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'

const ids: {
  [key: string]: string
} = {
  production: 'UA-146042032-1',
  staging: 'UA-146042032-2',
  test: 'UA-146042032-3',
  development: 'UA-146042032-3',
}
const isDev = APP_TYPE === 'development'
// @ts-ignore window.Cypress
const isE2E = Boolean(window.Cypress)
const sendHitTask = !(isDev || isE2E)

Vue.use(VueAnalytics, {
  id: ids[APP_TYPE || 'development'],
  router,
  debug: {
    sendHitTask,
  },
  set: [{ field: 'anonymizeIp', value: true }],
})
