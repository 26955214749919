import { growthBookService } from '@/plugins/growthBookService'
import store from '@/store'
import { Feature, FeatureKeys } from '@/types'
import { getModule, Module, VuexModule } from 'vuex-module-decorators'

export interface IFeaturesState {
  features: Record<string, boolean>
}
@Module({ dynamic: true, store, name: 'features', namespaced: true })
export default class Features extends VuexModule implements IFeaturesState {
  features: Record<string, boolean> = {}

  get feedback() {
    return (appId: number | undefined) => {
      return growthBookService.getFeatureValue(
        FeatureKeys.FEEDBACK,
        this.defaultValue(FeatureKeys.FEEDBACK),
        appId,
      )
    }
  }

  get usageMetrics() {
    return (appId: number | undefined) => {
        return growthBookService.getFeatureValue(
          FeatureKeys.USAGE_METRICS,
          this.defaultValue(FeatureKeys.USAGE_METRICS),
          appId,
        )
      }
  }

  get defaultValue() {
    return (feature: Feature) => !this.disabledByDefault.includes(feature)
  }

  get disabledByDefault(): Feature[] {
    return [FeatureKeys.FEEDBACK, FeatureKeys.USAGE_METRICS]
  }
}

export const FeaturesModule = getModule(Features)
