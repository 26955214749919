
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class AwsSubscriptionStatusModal extends Vue {
  get activeAwsSubscription() {
    return this.$typedStore.org.activeAwsSubscription
  }

  get name() {
    return this.$typedStore.org.name
  }

  get awsSubscription() {
    return this.$typedStore.org.awsSubscription
  }

  get dimension() {
    if (!this.awsSubscription) {
      return null
    }
    const { dimension } = this.awsSubscription
    const prefix = dimension.slice(0, 5)
    if (prefix === 'plan-' || prefix === 'plan_') {
      // expected format
      return dimension.slice(5, 6).toUpperCase() + dimension.slice(6)
    } else {
      return dimension
    }
  }

  get expirationDate() {
    if (!this.awsSubscription) {
      return null
    }
    return new Date(this.awsSubscription.expirationDate).toLocaleDateString(
      undefined, // will use browser default locale
      { year: 'numeric', month: 'long', day: 'numeric' },
    )
  }

  get rows() {
    return [
      { label: 'Organisation', value: this.name },
      { label: 'Plan', value: this.dimension },
      { label: 'Subscription Status', value: this.activeAwsSubscription ? 'Active' : 'Expired' },
      { label: 'Expiration Date', value: this.expirationDate },
    ]
  }
}
